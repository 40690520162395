import RolesActionsCell from '../components/RolesActionsCell';

export default [
    {
        title: '#',
        width: 38,
        align: 'center',
        render: (h, params) => {
            return h('div', [
                h(RolesActionsCell, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },

    {
        title: 'Role Name',
        key: 'Name',
        minWidth: 80,
    },
]
