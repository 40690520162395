import userPersonalInfo from  './components/userPersonalInfo';
import userDetails from  './components/userDetails';
import userRoles from  './components/userRoles';
import userAwards from  './components/userAwards';
import userDocuments from  './components/userDocuments';
// import salary from  './components/salary'; 
export default
[
    {
        id: 1,
        title: 'Personal Info',
        component: userPersonalInfo,
        isActive: true,
    },
    {
        id: 2,
        title: 'Details',
        component: userDetails,
        isActive: false,
        isDisabled: true
    },
    {
        id: 3,
        title: 'Roles',
        component: userRoles,
        isActive: false,
        isDisabled: true
    },
    {
        id: 4,
        title: 'Rewards & Recognition',
        component: userAwards,
        isActive: false,
        isDisabled: true
    },
    {
        id: 5,
        title: 'Document List',
        component: userDocuments,
        isActive: false,
        isDisabled: true
    },
    // sagar add salary page 
    // {
        // id: 6,
        // title: 'Salary Fixation',
        // component: salary,
        // isActive: false,
        // isDisabled: true
    // }
]