<template>
  <!-- <FormWrapper> -->
    
      <div style="width: 100%;margin-left: 10%;">
        <div style="width: 125px;
    margin-bottom: 5px;padding-bottom: 15px;">
          <inputDate
          label="Effective From"
          v-model="formRight.effectiveFrom"
          rules="required"
        />
        </div>
     
        <table
          class="leave_statement1"
          width="800"
          border="0"
          cellspacing="0"
          cellpadding="3"
        >
          <tbody>
            <tr>
              <td>
                <strong
                  >Name<span class="Mandatory"> </span>:
                  <span>{{ formLeft.name }}</span></strong
                >
              </td>
              <td colspan="2">
                <strong
                  >Department : <span>{{ formLeft.department }}</span></strong
                >
              </td>
            </tr>
            <tr>
              <td style=" width: 28%;">
                <strong>Last Increment Date :&nbsp;

                  <span>{{ formLeft.lastIncrement }}</span></strong
                >
              </td>
              <td colspan="2">
                <strong
                  >Present Increment Date <span class="redcls">*</span> :&nbsp;
                  <span v-if="viewMode">{{ formRight.presentIncrement }}</span>
                  <div v-if="!viewMode" style="width: 130px;
    position: absolute;
    margin-top: -21px;
    margin-left: 156px;">
                  <inputDate 
                    v-model="formRight.presentIncrement"
                    
                  />
                  </div>
                </strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Details</strong>
              </td>
              <td width="100px" align="center">
                <strong>Previous</strong>
              </td>
              <td width="150px" align="center">
                <strong>Present</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Designation<span class="redcls">*</span></strong> &nbsp;
              </td>
              <td align="center">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastDesign"
                  disabled="disabled"
                  >{{ formLeft.designation }}</span
                >
              </td>
              <td align="center">
                <span v-if="viewMode">{{ formRight.designationName }}</span>
                <!-- <FormSelect
                  :items="designationList"
                  item-name="NAME"
                  item-value="ID"
                  v-model="formRight.designation"
                  class="Dropstyle"
                  v-if="!viewMode"
                /> -->
                <select v-model="formRight.designation" class="Dropstyle">
    <Option v-for="(designationList, i) in designationList" :value="getItemValue(designationList.ID)" :key="i">{{ getItemName(designationList.NAME) }}</Option>
  </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Group<span class="redcls">*</span></strong>
              </td>

              <td align="center">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastGroup"
                  disabled="disabled"
                  >{{ formLeft.group }}</span
                >
              </td>
              <td align="center">
                <span v-if="viewMode">{{ formRight.groupName }}</span>
                <!-- <FormSelect
                  :items="hrGroupList"
                  item-name="name"
                  item-value="id"
                  v-model="formRight.hrGroupId"
                  class="Dropstyle"
                  v-if="!viewMode"
                /> -->
                <select v-model="formRight.hrGroupId" class="Dropstyle" @change="setDataByGrade($event)">
    <Option v-for="(hrGroupList, i) in hrGroupList" :value="getItemValue(hrGroupList.id)" :key="i">{{ getItemName(hrGroupList.name) }}</Option>
  </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Grade<span class="redcls">*</span></strong>
              </td>

              <td align="center">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastGrade"
                  disabled="disabled"
                  >{{ formLeft.grade }}
                </span>
              </td>
              <td align="center">
                <span v-if="viewMode">{{ formRight.gradeName }} </span>

                <!-- <FormSelect
                  :items="gradeList"
                  item-name="Grade"
                  item-value="Grade"
                  v-model="formRight.grade"
                  :onChange="setDataByGrade"
                  class="Dropstyle"
                  v-if="!viewMode"
                /> -->
                         <select v-model="formRight.grade" class="Dropstyle" @change="setDataByGrade($event)">
    <Option v-for="(gradeList, i) in gradeList" :value="getItemValue(gradeList.Grade)" :key="i">{{ getItemName(gradeList.Grade) }}</Option>
  </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Shift<span class="redcls">*</span></strong>
              </td>
              <td align="center">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastShiftType"
                  disabled="disabled"
                  >{{ formLeft.shift }}</span
                >
              </td>
              <td align="center">
                <span v-if="viewMode">{{ formRight.shiftName }}</span>
                <!-- <FormSelect
                  :items="shiftType"
                  item-name="Name"
                  item-value="id"
                  v-model="formRight.shiftId"
                  class="Dropstyle"
                  v-if="!viewMode"
                /> -->
                <select v-model="formRight.shiftId" class="Dropstyle">
    <Option v-for="(shiftType, i) in shiftType" :value="getItemValue(shiftType.id)" :key="i">{{ getItemName(shiftType.Name) }}</Option>
  </select>
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <strong>Salary</strong>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td class="alignSubhead">Basic <span class="redcls">*</span></td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastBasic"
                  disabled="disabled"
                  >{{ formLeft.basicSalary }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.basicSalaryN }}</span>

                <InputAmount class="inputStyle"
                  v-if="!viewMode"
                  v-model="formRight.basicSalaryAmount"
                  v-on:keyup="inputBasicSalaryAmount"
              style="padding:0px;"
                />
              </td>
            </tr>
            <tr>
              <td class="alignSubhead">
                HRA
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastHRA"
                  disabled="disabled"
                  >{{ formLeft.HRA }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.HRA }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.HRA"
                  v-on:keyup="setDataByGrade"
                  v-if="!viewMode"
                />
              </td>
            </tr>
            <!-- <tr>
              <td class="alignSubhead">
                Conveyance
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastConvey"
                  disabled="disabled"
                  >{{ formLeft.conveyance }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.conveyance }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.conveyance"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Monthly Allowance</strong>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr id="ctl00_ContentPlaceHolder1_trSoftTechProff">
              <td class="alignSubhead">
                Professional
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastProfess"
                  disabled="disabled"
                  >{{ formLeft.professional }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.professional }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.professional"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>

            <tr>
              <td class="alignSubhead">
                Special&nbsp;
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastSpecial"
                  disabled="disabled"
                  >{{ formLeft.special }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.special }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.special"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr id="ctl00_ContentPlaceHolder1_trFamily">
              <td class="alignSubhead">
                Family
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastfamily"
                  disabled="disabled"
                  >{{ formLeft.family }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.family }}</span>
                <InputAmount class="inputStyle"
                  v-model="formRight.family"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>

            <tr>
              <td class="alignSubhead">
                Shift
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastshift"
                  disabled="disabled"
                  >{{ formLeft.shiftAll }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.shiftAll }}</span>
                <InputAmount class="inputStyle"
                  v-model="formRight.shiftAll"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr>
              <td class="alignSubhead">
                Additional <span class="redcls">*</span>
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastAdditional"
                  disabled="disabled"
                  >{{ formLeft.additional }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.additional }}</span>
                <InputAmount class="inputStyle"
                  v-model="formRight.additional"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr> -->
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <strong>Monthly Fixed Salary</strong>
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastMonthlyfixedsalary"
                  disabled="disabled"
                  >{{ formLeft.monthlyFixedSalary }}</span
                >
              </td>
              <td align="right">
                <strong>
                  <span id="ctl00_ContentPlaceHolder1_lblMonthlyFixedSalary">{{
                    formRight.monthlyFixedSalaryN
                  }}</span></strong
                >
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <strong>Monthly Reimbursement</strong>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td class="alignSubhead">
                Mobile / Phone
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastMPhone"
                  disabled="disabled"
                  >{{ formLeft.mobile }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.mobile }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.mobile"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr id="ctl00_ContentPlaceHolder1_trConveyancereb">
              <td class="alignSubhead">
                Conveyance
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastMConvey"
                  disabled="disabled"
                  >{{ formLeft.conveyenceReimbursement }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{
                  formRight.conveyenceReimbursement
                }}</span>
                <InputAmount class="inputStyle"
                  v-model="formRight.conveyenceReimbursement"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>

            <tr id="ctl00_ContentPlaceHolder1_trBusinessDevelopmentreb">
              <td class="alignSubhead">
                Business Development
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastBussinessDevel"
                  disabled="disabled"
                  >{{ formLeft.businessDevelopment }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.businessDevelopment }}</span>
                <InputAmount class="inputStyle"
                  v-model="formRight.businessDevelopment"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr id="ctl00_ContentPlaceHolder1_trMedicalReimbursement">
              <td class="alignSubhead">
                Medical Reimbursement
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblpastMedicalReimb"
                  disabled="disabled"
                  >{{ formLeft.medicalReimbursement }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{
                  formRight.medicalReimbursement
                }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.medicalReimbursement"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr>
              <td class="alignSubhead">
                Monthly Performance Incentive

              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastBussinessDevel"
                  disabled="disabled"
                  >{{ formLeft.monthlyPerformanceIncentive }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.monthlyPerformanceIncentive }}</span>
                <InputAmount class="inputStyle"
                  v-model="formRight.monthlyPerformanceIncentive"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <!-- <tr id="ctl00_ContentPlaceHolder1_trAnnualReimbursement">
              <td>
                <strong>Annual Reimbursement</strong>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr> -->

            <!-- <tr id="ctl00_ContentPlaceHolder1_trMedicalReimbursement">
              <td class="alignSubhead">
                Medical Reimbursement
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblpastMedicalReimb"
                  disabled="disabled"
                  >{{ formLeft.medicalReimbursement }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{
                  formRight.medicalReimbursement
                }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.medicalReimbursement"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr> -->
            <tr>
              <td>
                <strong>Monthly Salary Payable</strong>
              </td>

              <td align="right">
                <span
                  disabled="disabled"
                  >{{ formLeft.monthlySalaryPayable }}</span
                >
              </td>
              <td align="right">
                <strong>
                  <span>{{
                    formRight.monthlySalaryPayable
                  }}</span></strong
                >
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <strong>Others</strong>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <!-- <tr>
              <td class="alignSubhead">
                Leave Encashment
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastleaveEncash"
                  disabled="disabled"
                  >{{ formLeft.leaveEncashment }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.leaveEncashment }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.leaveEncashment"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>
            <tr>
              <td class="alignSubhead">
                Medical Insurance
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastMedicalInsur"
                  disabled="disabled"
                  >{{ formLeft.medicalInsurance }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.medicalInsurance }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.medicalInsurance"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr> -->
            <tr id="ctl00_ContentPlaceHolder1_trRelationshipAward">
              <td class="alignSubhead">
                Relationship Award
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastRelationship"
                  disabled="disabled"
                  >{{ formLeft.relationshipAward }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.relationshipAward }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.relationshipAward"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>

            <tr id="ctl00_ContentPlaceHolder1_trPF">
              <td class="alignSubhead">
                Provident Fund
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastProvidentFund"
                  disabled="disabled"
                  >{{ formLeft.providentFund }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.providentFund }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.providentFund"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>

            <tr>
              <td class="alignSubhead" style="width:25%;">
                Bonus
              </td>

              <td align="right" style="width:25%;">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastBonus"
                  disabled="disabled"
                  >{{ formLeft.bonus }}</span
                >
              </td>
              <td align="right" style="width:25%;">
                <span v-if="viewMode">{{ formRight.bonus }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.bonus"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
              <td align="right"  style="width:25%;">
                <span>{{ bonusNew }}</span>
              </td>
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr id="ctl00_ContentPlaceHolder1_trpaymentPay">
              <td class="alignSubhead">
                Performance Pay
              </td>
              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastPerformacePay"
                  disabled="disabled"
                  >{{ formLeft.performancePay }}</span
                >
              </td>
              <td align="right">
                <span v-if="viewMode">{{ formRight.performancePay }}</span>

                <InputAmount class="inputStyle"
                  v-model="formRight.performancePay"
                  v-if="!viewMode"
                  v-on:keyup="setDataByGrade"
                />
              </td>
            </tr>

            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <strong>Cost to the Company</strong>
              </td>

              <td align="right">
                <span
                  id="ctl00_ContentPlaceHolder1_lblPastCTC"
                  disabled="disabled"
                  >{{ formLeft.costToTheCompany }}</span
                >
              </td>
              <td align="right">
                <strong>
                  <span id="ctl00_ContentPlaceHolder1_lblCTC">{{
                    formRight.costToTheCompany
                  }}</span></strong
                >
              </td>
              <td align="right">
                <strong>
                  <span id="ctl00_ContentPlaceHolder1_lblCTCannually">{{
                    costToTheCompanyNew
                  }}</span></strong
                >
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <strong>Note :</strong>
        <br />
        <span id="ctl00_ContentPlaceHolder1_lblNotes"
          >Incentive and Local Conveyance (if applicable) as per Company
          Policies</span
        >
        <br />
        <span id="ctl00_ContentPlaceHolder1_PaymentNote">
          Performance Pay will be paid Twice a area in the Month of April and
          October</span
        >
        <br />
        <br />
        <table
          class="leave_statement1"
          width="640"
          border="0"
          cellspacing="0"
          cellpadding="3"
        >
        <tbody>
            <tr>
              <td width="250px">
                <strong>
                  <!-- <span v-if="!viewMode && appraisalCount == 0">
                    Date of Joining
                  </span>
                  <span v-else-if="!viewMode && appraisalCount == 1"></span>
                   {{DOC}}
                  </span>
                  <span v-else-if="!viewMode">
                    Date of Increment
                  </span>  -->
                  <span>{{DOJ}}</span>
                </strong>
              </td>
              <td align="center">
                <!-- <span v-if="!viewMode && appraisalCount == 0">{{
                  formFooter.dateOfJoining
                }}</span>
                <span v-else-if="!viewMode && appraisalCount == 1">{{
                  formFooter.dateOfIncrement
                }}</span>
                 <span v-else-if="!viewMode">
                 {{ formFooter.dateOfJoining}}
                  </span>  -->
                  <span v-if="!viewMode">{{
                  formFooter.dateOfJoining
                }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong> <span>Service Agreement</span></strong>
              </td>
              <td align="center">
                <span>{{ formFooter.serviceAgreement }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  <!-- <span v-if="!viewMode && appraisalCount == 0">
                    Date of Confirmation
                  </span>
                  <span v-else-if="!viewMode  && appraisalCount == 1"
                    >{{DOJ}}</span
                  >
                  <span v-else-if="!viewMode"
                    >Date of next Increment</span
                  >
               -->
               <span>{{DOC}}</span>
                </strong>
              </td>
              <td align="center">
                <!-- <span v-if="!viewMode && appraisalCount == 1 ">{{
                  formFooter.dateOfNextIncrement
                }}</span>
                <span v-else-if="!viewMode && appraisalCount == 0">{{
                   formFooter.dateOfConfirmation
                }}</span>
                 <span v-else-if="!viewMode"
                    >{{formFooter.dateOfNextIncrement}}</span
                  > -->
                  <span v-if="!viewMode">{{
                  formFooter.dateOfConfirmation
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />
        <br />
        <table width="750" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td>
                <strong>Signature HR Executive</strong>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                <strong>Signature of HR Manager</strong>
              </td>
              <td>
                <strong>Signature of the CEO</strong>
              </td>
              <td>
                <span
                  id="ctl00_ContentPlaceHolder1_Label10"
                  disabled="disabled"
                  style="display:inline-block;width:107px;"
                ></span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Date</strong> :<span
                  id="ctl00_ContentPlaceHolder1_lblLastIncrementDate24"
                ></span>
              </td>
              <td>
                &nbsp;
              </td>
              <td>
                <strong>Date</strong> :<span
                  id="ctl00_ContentPlaceHolder1_lblLastIncrementDate25"
                ></span>
              </td>
              <td>
                <strong>Date</strong> :<span
                  id="ctl00_ContentPlaceHolder1_lblLastIncrementDate26"
                ></span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br />
        <!-- </div>
      <div class="col-sm-2"></div>
    </FormRow> -->
    <!-- <ButtonGroup v-if="!viewMode"> -->
      <FormButton type="success" @click="sendData()" style=" position: absolute;
    margin-left: 0px;">Save</FormButton>
    <!-- </ButtonGroup> -->
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <div slot="footer">
          <FormButton
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Ok</FormButton
          >
        </div>
      </div>
    </Popup>
          <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
      </div>


  <!-- </FormWrapper> -->
</template>

<script>
// import FormWrapper from "Components/form/FormWrapper";
import loadingMixin from "Mixins/loadingMixin";
import {
  getDesignation,
  getGrades,
  generateSalaryFixation,
  getAllShiftType,
  getAllHRGroup,
  // getPastSalaryFixation,
  getSalaryFixation,
  staffApprasial,
} from "../../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import moment from 'moment';
export default {
  name: "index",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    // FormWrapper,
    InputDate,
    // ValidationObserver,
  },
  // props: {
  //   basicAmount: [String, Number],
  //   staffId: [String, Number],
  //   additionalAmount: [String, Number],

  // },
  props: ["value"],
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      formLeft: {
        designation: null,
        group: null,
        grade: null,
        shift: null,
        basicSalary: null,
        HRA: null,
        conveyance: null,
        professional: null,
        special: null,
        family: null,
        shiftAll: null,
        additional: null,
        mobile: null,
        conveyenceReimbursement: null,
        businessDevelopment: null,
        medicalReimbursement: null,
        leaveEncashment: null,
        medicalInsurance: null,
        relationshipAward: null,
        providentFund: null,
        bonus: null,
        performancePay: null,
        costToTheCompany: null,
        dateofConfirmation: null,
        dateofIncrement: null,
        serviceAgreement: null,
        bonusAnually: null,
        monthlyFixedSalary: null,
        monthlyPerformanceIncentive:null,
        monthlySalaryPayable:null,
      },
      formRight: {
        designationName: "",
        designation: null,
        basicSalaryAmount: 0,
        basicSalaryN: "",
        hrGroupId: null,
        groupName: "",
        name: null,
        department: null,
        shift: null,
        shiftName: "",
        gradeName: "",
        lastIncrement: null,
        presentIncrement:new Date().toISOString().slice(0,10) ,
        HRA: 0,
        conveyance: 0,
        professional: 0,
        special: 0,
        family: 0,
        shiftId: 0,
        additional: 0,
        monthlyFixedSalary: 0,
        monthlyFixedSalaryN: "",
        mobile: 0,
        businessDevelopment: 0,
        conveyenceReimbursement:2000, //default conveyenceReimbursement
        medicalReimbursement: 0,
        leaveEncashment: 0,
        medicalInsurance: 0,
        relationshipAward: 0,
        providentFund: 0,
        bonus: 0,
        performancePay: 0,
        softwareTechnicalProfessional: 0,
        costToTheCompany: 0,
        costToTheCompanyAnnual: 0,
        dateofIncrement: "",
        monthlyPerformanceIncentive:0,
        monthlySalaryPayable:null,
        effectiveFrom:new Date().toISOString().slice(0,10) ,
      },
      formFooter: {
        dateOfJoining: "",
        dateOfIncrement: "",
        dateOfConfirmation: "",
        serviceAgreement: "",
        dateOfNextIncrement: "",
      },
      designationList: [],
      gradeList: [],
      shiftType: [],
      hrGroupList: [],
      isOnProbation: 0,
      appraisalCount:0,
      bonusNew: 0,
      costToTheCompanyNew: 0,
      viewMode: false,
      DOC:'Date of Confirmation',
      DOJ:'Date of Joining',
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {
  },
  created() {
    this.getDropDownData();
  },
  methods: {
    sendData() {
      let pfDeduction=0;
      if(this.formRight.providentFund>0){
        pfDeduction=1;
      }else{
        pfDeduction=0;
      }
      const data = {
        staffId: this.value.data.userId,
        appraisedOn: this.formRight.presentIncrement,
        appraisedBy: this.value.data.managerID,
        effectiveFrom: this.formRight.presentIncrement,
        designationId: this.formRight.designation,
        hrGroupId: this.formRight.hrGroupId,
        grade: this.formRight.grade,
        shiftId: this.formRight.shiftId,
        basicSalary: this.formRight.basicSalaryAmount,
        additional: this.formRight.additional,
        deductPF:pfDeduction,
        // ratingId: this.form.rating,
        CTC:this.formRight.costToTheCompany.match(/,/g)?parseFloat(this.formRight.costToTheCompany.replace(/,/g, '')):this.formRight.costToTheCompany,
        HRA: this.formRight.HRA,
        conveyance: this.formRight.conveyance,
        softwareTechnicalProfessinalAllowance: this.formRight.professional,
        specialAllowance: this.formRight.special,
        familyAllowance: this.formRight.family,
        shiftAllowance: this.formRight.shiftAll,
        mobileReimbursement: this.formRight.mobile,
        conveyenceReimbursement: this.formRight.conveyenceReimbursement,
        businessdevelopmentReimbursement: this.formRight.businessDevelopment,
        medicalReimbursement: this.formRight.medicalReimbursement,
        leaveEncashment: this.formRight.leaveEncashment,
        medicalInsurance: this.formRight.medicalInsurance,
        relationshipAward: this.formRight.relationshipAward,
        bonus: this.formRight.bonus,
        performancePay: this.formRight.performancePay,
        staffPF: this.formRight.providentFund,
        modifiedBy: this.formRight.modifiedBy,
        isAppraisal: 'N',
        userLastIncDate: this.formFooter.dateOfJoining,
        userAppraisalDue: this.formFooter.dateOfConfirmation,
        presentIncDate: this.formRight.presentIncrement,
        monthlyPerformanceIncentive: this.formRight.monthlyPerformanceIncentive,
      };
      staffApprasial(data).then((res) => {
        if (res.data[0].status == 1) {
          this.showMessage.isVisible = true;
          this.showMessage.message = res.data[0].message;
        } else {
          this.infoMessage.isVisible = true;
          this.infoMessage.message = res.data[0].message;
        }
      });
    },
    prevStep() {
      this.$emit("prevStep");
    },
    async getDropDownData() {
        await getDesignation().then((res) => {
          this.designationList = res.data;
          this.formRight.designation=this.value.data.designationId;
        });
        await getGrades().then((res) => {
          this.gradeList = res.data;
          this.formRight.grade=this.value.data.gradeId;
        });
        await getAllHRGroup().then((res) => {
          this.hrGroupList = res.data;
          this.formRight.hrGroupId=this.value.data.hrGroupId;
        });
        await getAllShiftType().then((res) => {
          this.shiftType = res.data;
          this.formRight.shiftId=this.value.data.shiftId;
        });
        this.salaryFixation();
    },
    async salaryFixation() {
      const data = {
        staffId:this.value.data.userId,
        effectivedate:this.formRight.effectiveFrom,
        year:this.formRight.effectiveFrom
          ?this.formRight.effectiveFrom.split("-")[0]
          : "",
        month:this.formRight.effectiveFrom
          ?this.formRight.effectiveFrom.split("-")[1]
          : "",
          isAppraisal:0,
      };
      await getSalaryFixation(data).then((res) => {
        this.formLeft.name = res.data[0].NAME;
        this.formLeft.department = res.data[0].Department;
        this.formLeft.lastIncrement = res.data[0].LastIncrementDate;
        if(res.data[0].AppraisalCount!=0){

        this.formLeft.designation = res.data[0].Designation;

        // this.formLeft.presentIncrement = res.data[0].NextIncrementDate;
          if(res.data[0].BasicSalary){
          this.formLeft.shift = res.data[0].Shift;
          }

        // prefix pr means previous
        this.formLeft.grade = res.data[0].Grade_id;
        this.formLeft.basicSalary = res.data[0].BasicSalary?res.data[0].BasicSalary.toLocaleString('en-IN'):'';
        this.formLeft.HRA = res.data[0].HRA?res.data[0].HRA.toLocaleString('en-IN'):'';

        this.formLeft.group = res.data[0].Group;
        this.formLeft.conveyance = res.data[0].Conveyence;
        this.formLeft.professional = res.data[0].PerformancePay;
        this.formLeft.special = res.data[0].Special;
        this.formLeft.family = res.data[0].Family;
        this.formLeft.shiftAll = res.data[0].ShiftAll;
        this.formLeft.additional = res.data[0].Additional;
        this.formLeft.mobile=res.data[0].MobilePhone?res.data[0].MobilePhone:'';
        this.formLeft.conveyenceReimbursement =
          res.data[0].conveyenceReimbursement? res.data[0].conveyenceReimbursement:'';
        this.formLeft.businessDevelopment = res.data[0].BusinessDevelopment?res.data[0].BusinessDevelopment:'';
        this.formLeft.medicalReimbursement = res.data[0].MedicalReimbursement?res.data[0].MedicalReimbursement:'';
        this.formLeft.leaveEncashment = res.data[0].LeaveEncashment;
        this.formLeft.medicalInsurance = res.data[0].MedicalInsurance;
        this.formLeft.relationshipAward = res.data[0].RelationshipAward?res.data[0].RelationshipAward:'';
        this.formLeft.providentFund = res.data[0].ProvidentFund?res.data[0].ProvidentFund:'';
        this.formLeft.bonus = res.data[0].Bonus?res.data[0].Bonus:'';
        this.formLeft.performancePay = res.data[0].PerformancePay?res.data[0].PerformancePay:'';

        this.formLeft.costToTheCompany = res.data[0].CostToTheCompany?res.data[0].CostToTheCompany.toLocaleString('en-IN'):'';
        // this.formLeft.costToTheCompanyAnnual =
        //   res.data[0].CostToTheCompanyAnnual;
        // this.formLeft.dateofConfirmation = res.data[0].DateOfConfirmation;
        // this.formLeft.dateofIncrement = res.data[0].DateOfIncrement;
        this.formLeft.serviceAgreement = res.data[0].ServiceAgreement;
        this.formLeft.bonusAnually = res.data[0].BonusAnually?res.data[0].BonusAnually:'';
        this.formLeft.monthlyFixedSalary = res.data[0].MonthlyFixedSalary?res.data[0].MonthlyFixedSalary.toLocaleString('en-IN'):'';
      }
        // footer table
        this.formFooter.serviceAgreement = res.data[0].ServiceAgreement;
        this.isOnProbation = res.data[0].IsOnProbation;
        this.appraisalCount = res.data[0].AppraisalCount;
        this.formFooter.dateOfIncrement = res.data[0].DateOfIncrement;
       this.formFooter.dateOfNextIncrement =res.data[0].NextIncrementDate;
        if (res.data[0].AppraisalCount==0) {
          this.formFooter.dateOfJoining = res.data[0].LastIncrementDate;
          this.formFooter.dateOfConfirmation = res.data[0].NextIncrementDate;
        } else if (res.data[0].AppraisalCount==1) {
          this.formFooter.dateOfJoining = res.data[0].DateOfIncrement;
          this.formFooter.dateOfConfirmation = res.data[0].NextIncrementDate;
          this.DOC= "Date of Increment";
                this.DOJ= " Date of Confirmation";
        }
        else
            {
              this.formFooter.dateOfJoining = res.data[0].DateOfIncrement;
              this.formFooter.dateOfConfirmation =moment(res.data[0].NextIncrementDate).add(1, 'Y').format('DD-MMM-YYYY');
                this.DOJ= "Date of Increment";
                this.DOC= "Date of next Increment";
            }

   
        this.fillRightColunm(res.data[0])
      });
    },
    fillRightColunm(data){
      this.formRight.name = data.NAME;
        this.formRight.department = data.Department;

        // this.formRight.designation = data.Designation_id;
        this.formRight.lastIncrement = data.LastIncrementDate;
        if(data.IsOnProbation==1){
          this.formRight.presentIncrement = data.DateOfIncrement;
        }
    else{
      this.formRight.presentIncrement = data.NextIncrementDate;
    }
        // this.formRight.shift = data.Shift;
        // this.formRight.grade = data.Grade_id;
        this.formRight.basicSalaryAmount =data.BasicSalary?parseInt(data.BasicSalary):0;
        this.formRight.monthlyFixedSalaryN=data.MonthlyFixedSalary.toLocaleString('en-IN');
        this.formRight.HRA = data.HRA?parseInt(data.HRA):0;

        this.formRight.group = parseInt(data.Group);
        this.formRight.conveyance = data.Conveyence?parseInt(data.Conveyence):0;
        this.formRight.professional =data.PerformancePay?parseInt(data.PerformancePay):0;
        this.formRight.special =data.Special? parseInt(data.Special):0;
        this.formRight.family =data.Family? parseInt(data.Family):0;
        this.formRight.shiftAll = parseInt(data.ShiftAll);
        this.formRight.additional = parseInt(data.Additional);
        this.formRight.mobile=data.MobilePhone?parseInt(data.MobilePhone):0;
        this.formRight.conveyenceReimbursement =data.ConveyenceReimbursement?parseInt(data.ConveyenceReimbursement):0;
        this.formRight.businessDevelopment =data.BusinessDevelopment?parseInt(data.BusinessDevelopment):0;
        this.formRight.medicalReimbursement = data.MedicalReimbursement?parseInt(data.MedicalReimbursement):0;
        this.formRight.leaveEncashment = data.LeaveEncashment?parseInt(data.LeaveEncashment):0;
        this.formRight.medicalInsurance = data.MedicalInsurance?parseInt(data.MedicalInsurance):0;
        this.formRight.relationshipAward = data.RelationshipAward?parseInt(data.RelationshipAward):0;
        this.formRight.providentFund =data.ProvidentFund?parseInt(data.ProvidentFund):0;
        this.formRight.bonus = parseInt(data.Bonus);
        this.formRight.performancePay =data.PerformancePay?parseInt(data.PerformancePay):0;
        
        this.bonusNew = parseInt(data.BonusAnually);
        this.formRight.costToTheCompany =data.CostToTheCompany.toString();
        this.costToTheCompanyNew =
          parseInt(data.CostToTheCompanyAnnual);
        this.formRight.dateofConfirmation = parseInt(data.DateOfConfirmation);
    },

    inputBasicSalaryAmount() {
      this.formRight.HRA = this.formRight.basicSalaryAmount * 0.6; //HRA case formula
      this.formRight.providentFund = this.formRight.basicSalaryAmount * 0.12; //providentFund
      this.formRight.medicalReimbursement = parseInt(
        this.formRight.basicSalaryAmount / 12
      ); //medical

      setTimeout(() => {
        // this.sum();
        this.setDataByGrade();
      }, 50);
    },
    // sum() {
    //     let salary = 0;
    //     let monthAllowance = 0;
    //     let monthlyReimbursement = 0;
    //     let annulReimbursement = 0;
    //     let other = 0;

    //     // salary
    //     salary =
    //       this.checkValue(this.formRight.basicSalaryAmount) +
    //       this.checkValue(this.formRight.HRA) +
    //       this.checkValue(this.formRight.conveyance);
    //     // monthAllowance
    //     monthAllowance =
    //       this.checkValue(this.formRight.professional) +
    //       this.checkValue(this.formRight.special) +
    //       this.checkValue(this.formRight.family) +
    //       this.checkValue(this.formRight.additional) +
    //       this.checkValue(this.formRight.shiftAll);
    //     // monthlyReimbursement
    //     monthlyReimbursement =
    //       this.checkValue(this.formRight.mobile) +
    //       this.checkValue(this.formRight.conveyenceReimbursement) +
    //       this.checkValue(this.formRight.businessDevelopment);
          
    //     // fixedSalary
    //     this.formRight.monthlyFixedSalaryN = salary + monthAllowance;
    //     // annuleReimbursement
    //     annulReimbursement = this.checkValue(this.formRight.medicalReimbursement);
    //     // othere
    //     other =
    //       this.checkValue(this.formRight.leaveEncashment)+this.checkValue(this.formRight.medicalInsurance )+
    //       this.checkValue(this.formRight.relationshipAward )+this.checkValue(this.formRight.providentFund )+
    //       this.checkValue(this.formRight.bonus)+
    //       this.checkValue(this.formRight.performancePay);
    //     // total=
    //     // <!-- total cost to company new -->
    //     this.formRight.costToTheCompany =
    //       salary +
    //       monthAllowance +
    //       monthlyReimbursement +
    //       annulReimbursement +
    //       other;
    //     this.costToTheCompanyNew = this.checkValue(this.formRight.costToTheCompany) * 12;
    //     // <!-- new bonus -->
    //     this.bonusNew = this.formRight.bonus * 12;

    // },
        sum() {
      if (!this.viewMode) {
        let salary = 0;
        let monthAllowance = 0;
        let monthlyReimbursement = 0;
        let annulReimbursement = 0;
        let other = 0;
      // salary
      salary =
          this.checkValue(this.formRight.basicSalaryAmount) +
          this.checkValue(this.formRight.HRA) 
        // monthAllowance
        monthAllowance =
          this.checkValue(this.formRight.professional) +
          this.checkValue(this.formRight.special) +
          this.checkValue(this.formRight.family) +
          this.checkValue(this.formRight.additional) +
          this.checkValue(this.formRight.shift);
        // monthlyReimbursement
        monthlyReimbursement =
          this.checkValue(this.formRight.mobile) +
          this.checkValue(this.formRight.conveyenceReimbursement) +
          this.checkValue(this.formRight.businessDevelopment)+this.checkValue(this.formRight.medicalReimbursement)+this.checkValue(this.formRight.monthlyPerformanceIncentive);
        // fixedSalary
        this.formRight.monthlyFixedSalaryN = salary.toLocaleString('en-IN');
        // monthlySalary payable
        this.formRight.monthlySalaryPayable=(salary +monthlyReimbursement).toLocaleString('en-IN')
        // annuleReimbursement
        annulReimbursement = this.checkValue(this.formRight.medicalReimbursement);
        // othere
        other =
          this.checkValue(this.formRight.relationshipAward )+
          this.checkValue(this.formRight.providentFund )+
          this.checkValue(parseInt(this.formRight.bonus))
        // <!-- total cost to company new -->
        this.formRight.costToTheCompany =(salary +monthlyReimbursement +other).toLocaleString('en-IN');
        this.costToTheCompanyNew = (this.checkValue(salary +monthlyReimbursement +other) * 12).toLocaleString('en-IN');

        // <!-- new bonus -->
        this.bonusNew = (this.formRight.bonus * 12).toLocaleString('en-IN');
      }
    },
    checkValue(val){
return val?val:0
    },
    getItemValue(item) {
                return item[this.itemValue] || item
            },
            getItemName(item) {
                return item[this.itemName] || item
            },
    // setDataByGrade(event) {
    //   var value = event.target.value.trim();
    //   switch (value) {
    //     case "C0":
    //       this.formRight.bonus =
    //         (this.formRight.basicSalaryAmount / 100) * 8.33;
    //       this.formRight.mobile = 0;
    //       this.formRight.conveyenceReimbursement = 0;
    //       this.formRight.businessDevelopment = 0;
    //       this.formRight.medicalInsurance = 300;
    //       this.formRight.relationshipAward = 0;
    //       break;
    //     case "C1":
    //       this.formRight.bonus =
    //         (this.formRight.basicSalaryAmount / 100) * 8.33;
    //       this.formRight.mobile = 0;
    //       this.formRight.conveyenceReimbursement = 0;
    //       this.formRight.businessDevelopment = 300;
    //       this.formRight.medicalInsurance = 60;
    //       this.formRight.relationshipAward = 0;
    //       break;
    //     case "C2":
    //       this.formRight.bonus =
    //         (this.formRight.basicSalaryAmount / 100) * 8.33;
    //       this.formRight.mobile = 0;
    //       this.formRight.conveyenceReimbursement = 1000;
    //       this.formRight.businessDevelopment = 0;
    //       this.formRight.medicalInsurance = 60;
    //       this.formRight.relationshipAward = 0;
    //       break;
    //     case "C3":
    //       this.formRight.bonus =
    //         (this.formRight.basicSalaryAmount / 100) * 8.33;
    //       this.formRight.mobile = 1000;
    //       this.formRight.conveyenceReimbursement = 2000;
    //       this.formRight.businessDevelopment = 1000;
    //       this.formRight.medicalInsurance = 500;
    //       this.formRight.relationshipAward = 0;
    //       break;
    //     case "E11":
    //       this.formRight.bonus =
    //         (this.formRight.basicSalaryAmount / 100) * 8.33;
    //       this.formRight.mobile = 500;
    //       this.formRight.conveyenceReimbursement = 500;
    //       this.formRight.businessDevelopment = 0;
    //       this.formRight.medicalInsurance = 600;
    //       this.formRight.relationshipAward = 278;
    //       break;
    //     case "E12":
    //       this.formRight.bonus =
    //         (this.formRight.basicSalaryAmount / 100) * 8.33;
    //       this.formRight.mobile = 500;
    //       this.formRight.conveyenceReimbursement = 500;
    //       this.formRight.businessDevelopment = 500;
    //       this.formRight.medicalInsurance = 600;
    //       this.formRight.relationshipAward = 278;
    //       break;
    //     case "E21":
    //       this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 10;
    //       this.formRight.mobile = 1000;
    //       this.formRight.conveyenceReimbursement = 1000;
    //       this.formRight.businessDevelopment = 1000;
    //       this.formRight.medicalInsurance = 700;
    //       this.formRight.relationshipAward = 500;
    //       break;
    //     case "E22":
    //       this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 10;
    //       this.formRight.mobile = 1000;
    //       this.formRight.conveyenceReimbursement = 2000;
    //       this.formRight.businessDevelopment = 2000;
    //       this.formRight.medicalInsurance = 700;
    //       this.formRight.relationshipAward = 500;
    //       break;
    //     case "E31":
    //       this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 15;
    //       this.formRight.mobile = 1500;
    //       this.formRight.conveyenceReimbursement = 3000;
    //       this.formRight.businessDevelopment = 2500;
    //       this.formRight.medicalInsurance = 1000;
    //       this.formRight.relationshipAward = 1000;
    //       break;
    //     case "E32":
    //       this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 15;
    //       this.formRight.mobile = 1500;
    //       this.formRight.conveyenceReimbursement = 3000;
    //       this.formRight.businessDevelopment = 5000;
    //       this.formRight.medicalInsurance = 1000;
    //       this.formRight.relationshipAward = 2000;
    //       break;
    //     case "P1":
    //       this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 20;
    //       this.formRight.mobile = 2500;
    //       this.formRight.conveyenceReimbursement = 5000;
    //       this.formRight.businessDevelopment = 7500;
    //       this.formRight.medicalInsurance = 1000;
    //       this.formRight.relationshipAward = 3000;
    //       break;
    //     case "P2":
    //       this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 20;
    //       this.formRight.mobile = 3000;
    //       this.formRight.conveyenceReimbursement = 5000;
    //       this.formRight.businessDevelopment = 10000;
    //       this.formRight.medicalInsurance = 1000;
    //       this.formRight.relationshipAward = 5000;
    //       break;
    //     default:
    //       console.log(value);
    //       break;
    //   }
    // },
    setDataByGrade() {
        if(this.formRight.grade){
      switch (this.formRight.grade.trim()) {
        case "C0":
          this.formRight.bonus =
            parseInt((this.formRight.basicSalaryAmount / 100) * 8.33);
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 0;
            }
            if (this.formRight.hrGroupId==5) { //for MKTG group
            this.formRight.businessDevelopment =0;
            }
            if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =2000;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 300;
          this.formRight.relationshipAward = 0;
          break;
        case "C1":
          this.formRight.bonus =
          parseInt((this.formRight.basicSalaryAmount / 100) * 8.33);
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 0;
            }
            else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =0;
              }
              else{
                this.formRight.businessDevelopment =0;
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement = 2000;
            }
            else{
              this.formRight.conveyenceReimbursement = 2000;
            }
          this.formRight.medicalInsurance = 60;
          this.formRight.relationshipAward = 0;
          break;
        case "C2":
          this.formRight.bonus =
          parseInt((this.formRight.basicSalaryAmount / 100) * 8.33);
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 0;
            }
            else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =0;
              }
              else{
                this.formRight.businessDevelopment =0;
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =3000;
            }
            else{
              this.formRight.conveyenceReimbursement =2000; 
            }
          this.formRight.medicalInsurance = 60;
          this.formRight.relationshipAward = 0;
          break;
        case "C3":
          this.formRight.bonus =
          parseInt((this.formRight.basicSalaryAmount / 100) * 8.33);
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 1000;
            }
            else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =1000;
              }
              else{
                this.formRight.businessDevelopment = 0;
              }
                   if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =4000;
            }
            else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 500;
          this.formRight.relationshipAward = 0;
          break;
        case "E11":
          this.formRight.bonus =
          parseInt((this.formRight.basicSalaryAmount / 100) * 8.33);
            if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 500;
            }else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =0;
              }else{
                this.formRight.businessDevelopment =0;
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =2500;
            }
            else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 600;
          this.formRight.relationshipAward = 278;
          break;
        case "E12":
          this.formRight.bonus =
          parseInt((this.formRight.basicSalaryAmount / 100) * 8.33);
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 0;
            }else{
              this.formRight.mobile = 0; 
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =500;
              }else{
                this.formRight.businessDevelopment =0;
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =2500;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 600;
          this.formRight.relationshipAward = 278;
          break;
        case "E21":
          this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 10;
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 1000;
            }else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =1000;
              }else{
                this.formRight.businessDevelopment =0;
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =3000;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 700;
          this.formRight.relationshipAward = 500;
          break;
        case "E22":
          this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 10;
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 1000;
            }else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =2000;
              }else{
                this.formRight.businessDevelopment =0; 
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =4000;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 700;
          this.formRight.relationshipAward = 500;
          break;
        case "E31":
          this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 15;
          this.formRight.medicalInsurance = 1000;
          this.formRight.relationshipAward = 1000;
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 1500;
            }else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =2500;
              }else{
                this.formRight.businessDevelopment =0;
              }
          if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =5000;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          break;
        case "E32":
          this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 15;
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 1500;
            }else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId==5) { //for MKTG group
              this.formRight.businessDevelopment =5000;
              }else{
                this.formRight.businessDevelopment =0;  
              }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =5000;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 1000;
          this.formRight.relationshipAward = 2000;
          break;
        case "P1":
          this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 20;
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
          this.formRight.mobile = 2500;
          }else{
            this.formRight.mobile = 0;
          }
        if (this.formRight.hrGroupId==5) { //for MKTG group
        this.formRight.businessDevelopment =7500;
        }else{
          this.formRight.businessDevelopment =0;
        }
              if (this.formRight.hrGroupId==6 || this.formRight.hrGroupId==5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =7000;
            }else{
              this.formRight.conveyenceReimbursement =2000;
            }
          this.formRight.medicalInsurance = 1000;
          this.formRight.relationshipAward = 3000;
          break;
        case "P2":
          this.formRight.bonus = (this.formRight.basicSalaryAmount / 100) * 20;
          if (this.formRight.hrGroupId==1 || this.formRight.hrGroupId==3 ||this.formRight.hrGroupId==5 || this.formRight.hrGroupId==6 ) { //for MKTG,SUPP,ITS,INFRA,HR,FM group
            this.formRight.mobile = 3000;
            }else{
              this.formRight.mobile = 0;
            }
          if (this.formRight.hrGroupId===5) { //for MKTG group
              this.formRight.businessDevelopment =1000;
              }else{
                this.formRight.businessDevelopment =0;
              }
              if (this.formRight.hrGroupId===6 || this.formRight.hrGroupId===5) { //for MKTG group SUPP
              this.formRight.conveyenceReimbursement =7000;
            }else{
              this.formRight.conveyenceReimbursement =2000; 
            }
          this.formRight.medicalInsurance = 1000;
          this.formRight.relationshipAward = 5000;
          break;
        default:
          console.log("default ");
          break;
      }
    }
      setTimeout(() => {
        this.sum();
      }, 50);
    },
  },
};
</script>
<style lang="scss">
.error {
  color: #ff3547;
  margin-top: 2px;
  max-width: 100%;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
// table style

.leave_statement1 {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif, Calibri;
}

.leave_statement1 h2 {
  font-size: 18px;
  font-weight: normal;
}
.leave_statement1 table {
  border-collapse: collapse;
}
// sagar comment uncomment when salary user
// .leave_statement1 td,th {
//   border: 1px solid #000000;
// }
.leave_statement1 td{
  border: 1px solid #000000;
}
.textboxstyle {
  text-align: right;
  text-align-last: right;
  float: right;
}
tr, td {
  padding: 0px 5px 0px 3px ;
  
}


.bckgrd {
  background-color: LightGrey;
}
.Dropstyle{
  text-align: center;
    text-align-last: center;
    font-size: 11px;
    // background: #f8f8f8;
    border: 1px #cecece solid;
}
.inputStyle{
  text-align: right;
    text-align-last: right;
    float: right;
    font-size: 11px;
     // background: #f8f8f8;
    // border: 1px #cecece solid;
    margin: 0px;
}
.redcls {
    color: #FF0000;
}
td{
  height: 25px;
}
.leave_statement1 input {
  padding: 0px 0px 0px 0px;
  height: 20px;
}
.leave_statement1 select {
  padding: 0px 0px 0px 5px;
  height: 20px;
  width:188px
}
</style>
