export default {
    data() {
        return {
            selectedRows: [],
            warningModal: {
                visible: false,
                buttons: {
                    ok: true,
                    confirm: false,
                    cancel: false
                }
            },
        }
    },
    methods: {
        showWarningModal(value) {
            this.warningModal.visible = value;
        },
        validateSelectedLength(value) {
            if (this.selectedRows.length === value) {
                return true;
            }
            if (value === 1 && this.selectedRows.length > 1) {
                this.warningModal.message = 'Select one row please.';
                this.warningModal.buttons.ok =true;
                this.warningModal.buttons.cancel =false;
                this.warningModal.buttons.confirm =false;
                this.showWarningModal(true);
                return false
            }
            if (this.selectedRows.length === 0) {
                this.warningModal.message = 'Select at least one row!';
                this.warningModal.buttons.ok =true;
                this.warningModal.buttons.cancel =false;
                this.warningModal.buttons.confirm =false;
                this.showWarningModal(true);
                return false
            }
            return true;
        },
   
        deleteSelectedValue(value) {
                this.warningModal.message = 'Are you sure you want to remove this row.';
                this.warningModal.buttons.ok =false;
                this.warningModal.buttons.cancel =true;
                this.warningModal.buttons.confirm =true;
                this.showWarningModal(true);
                return false
        },
        salaryValidate() {
            this.warningModal.message = 'Are you sure you want to release this salary.';
            this.warningModal.buttons.ok =false;
            this.warningModal.buttons.cancel =true;
            this.warningModal.buttons.confirm =true;
            this.showWarningModal(true);
            return false
    },
    }
}


