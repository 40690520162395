<template> 
    <FormWrapper>
        <PageTitle title="User Information" slot="title" link="/helpContent/User Information" />
        <br />
        <Wizard :steps="steps" @changeStep="setActiveStep"/>
        <ValidationObserver ref="validator" >
            <component :is="activeStep.component"
                ref="component"
                :stepId="activeStep.id"
                :value="form"
                :reference_id="form.data.id"
                :class="$style.formContent"
                :readonly='readonly'
                :isStaffViewing='isStaffViewing'
                :isManagerViewing='isManagerViewing'
            />
        </ValidationObserver>
        <Popup title="Information" type="info" :value="infoMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ infoMessage.message }}
        </Popup>
        <ButtonGroup>
            <FormButton type="primary" @click="prevStep" v-if="showButtons.prev" left-icon="ios-arrow-back">Previous</FormButton>
            <FormButton type="success" v-if="showSaveButton" @click="updateForm()">Save</FormButton>
            <FormButton type="primary" @click="nextStep" v-if="showButtons.next" right-icon="ios-arrow-forward">Next</FormButton>
        </ButtonGroup>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import Wizard from 'Components/wizard/Wizard.vue';
    import formStepsMixin from 'Mixins/formStepsMixin';
    import STEPS from './steps'
    import data from './data'
    import {getStaffInfoByUserID, getStaffDetailsByUserID, addUpdateStaffInfo, updateUserDetails, addUserRoleV1} from "../api";
    import { ValidationObserver } from "vee-validate";
    import PageTitle from 'Components/layout/PageTitle';
    import salary from  './components/salary'; 
    export default {
        name: 'UserManagement',
        mixins: [formStepsMixin],
        components: {
            FormWrapper,
            Wizard,
            PageTitle,
            ValidationObserver,
            salary,
        },
        data() {
            return {
                readonly: false,
                form: {
                    data
                },
                isManagerViewing: false,
                isStaffViewing: false,
                showSaveButton: true,
                infoMessage: {
                    isVisible: false,
                    message: ''
                },
            }
        },
        computed: {
            showButtons() {
                const activeStep = this.activeStep;
                if (activeStep) {
                    return {
                        next: activeStep.id < this.steps.length,
                        submit: activeStep.id === this.steps.length,
                        prev: activeStep.id > 1
                    }
                }
                return false
            },
            userData() {
                return this.$store.state.user.user
            }
        },
        watch: {
            '$route.name'(name) {
                this.setSteps(STEPS);
                if (name==='profileInfo') {
                    this.activateSteps(1);
                    this.setActiveStep(1);
                    this.getDataRequest();
                }
            }
        },
        created() {
            this.form.data={};
            //Sagar salary fixation tab show only edit mode
            if(this.$route.params.id && !this.findTab(STEPS)){
                    STEPS.push({
                    id: 6,
                    title: 'Salary Fixation',
                    component: salary,
                    isActive: false,
                    isDisabled: true
                })
                }
                else if(!this.$route.params.id && this.findTab(STEPS) && STEPS.length==6){ //Add 
                 STEPS.splice(STEPS.length-1, 1)
                }
            this.setSteps(STEPS);
            this.activateSteps(1);
            this.setActiveStep(1);
            this.getDataRequest();
        },
        methods: {
            findTab(items) {
            var check = items.find(item => { return item.id ===6 });
            return check === undefined ? false : true;
        },
            nextStep() {
                const nextStep = this.activeStep.id + 1;
                if(this.isManagerViewing === true){
                    //For Hr>Team>Profile (View mode)
                    this.moveStep(nextStep);
                } else{
                    //For Settings>My Profile>My Info (Add + View mode)
                    if(this.$route.name === 'profileInfo'){
                        //Save button should be only avilable for 'Profile Info step'
                        this.showSaveButton = false;
                        if(nextStep === 2){
                            this.updateForm(nextStep);
                        } else{
                            this.moveStep(nextStep);
                        }
                    } else{
                        //For Settings>Settings>Usermanagement>Add/Edit (Add/Edit mode)
                        //Save button should't be shown in Rewards or documents step
                        if(nextStep === 4 || nextStep === 5|| nextStep === 6){ //sagar add nextStep  condition nextStep===6
                            this.showSaveButton = false;
                        } else{
                            this.showSaveButton = true;
                        }
                        this.updateForm(nextStep);
                    }
                }
            },
            prevStep() {
                const nextStep = this.activeStep.id - 1;
                if(this.isManagerViewing === true){
                    //For Hr>Team>Profile (View mode)
                    this.moveStep(nextStep);
                } else{
                    //For Settings>My Profile>My Info (Add + View mode)
                    if(this.$route.name === 'profileInfo'){
                        //Save button should be only avilable for 'Profile Info step'
                        if(nextStep === 1){
                            this.showSaveButton = true;
                        } else{
                            this.showSaveButton = false;
                        }
                        this.moveStep(nextStep);
                    } else{
                        //For Settings>Settings>Usermanagement>Add/Edit (Add/Edit mode)
                        //Save button should't be shown in Rewards or documents step
                        if(nextStep === 4){
                            this.showSaveButton = false;
                        } else{
                            this.showSaveButton = true;
                        }
                        this.updateForm(nextStep);
                    }
                }
            },
            resetActivatedSteps(){
                this.steps.forEach(item => {
                    if(item.id !== 1){
                        item.isDisabled = true;
                    }
                    if(item.id == 1){
                        this.activateSteps(1);
                        this.setActiveStep(1);
                    }
                })
            },
            activateSteps(step) {
                this.steps.forEach(item => {
                    if(item.id === step){
                        item.isDisabled = false;
                    } else{
                        item.isDisabled = true;
                    }
                })
            },
            getDataRequest() {
                if(this.$route.name === 'profileInfo'){
                    //For Settings>My Profile>My Info (Add + View mode)
                    this.form.data.userId = this.userData.user_id;
                    this.isStaffViewing = true;
                    this.readonly = true;
                    this.getData();
                } else if(this.$route.params.id){
                    //For Settings>Settings>Usermanagement>Edit (Edit mode)
                    this.form.data.userId = atob(this.$route.params.id);

                    //For Hr>Team>Profile (View mode)
                    if(atob(this.$route.params.isManagerViewing) === '1'){
                        this.isManagerViewing = true;
                        this.readonly = true;
                        this.showSaveButton = false;
                    }
                    this.getData();
                } else{
                    //For Settings>Settings>Usermanagement>Add (Add mode)
                    this.form.data = {};
                    this.form.data.userId = (this.form.data.userId === undefined)?0:this.form.data.userId;
                }
            },
            async getData() {
                const filters = {};
                filters.userId = this.form.data.userId;
                const data = {
                    filterjson: {
                        filter: [filters]
                    }
                }; 
                await getStaffInfoByUserID(data).then((res)=>{
                    this.form.data.displayPicture = res.data[0].PhotoName;
                    this.form.data.salutation = res.data[0].Salutation.trim();
                    this.form.data.firstName = res.data[0].FirstName;
                    this.form.data.middleName = res.data[0].MiddleName;
                    this.form.data.lastName = res.data[0].LastName;
                    this.form.data.empCode = res.data[0].EmpCode;
                    this.form.data.gradeId = res.data[0].Grade_Id;
                    this.form.data.userName = res.data[0].UserName;
                    this.form.data.phoneNo1 = res.data[0].Phone1;
                    this.form.data.phoneNo2 = res.data[0].Phone2;
                    this.form.data.preferredPhone = res.data[0].PreferredPhone;
                    this.form.data.mobileNo = res.data[0].MobileNo;
                    this.form.data.emergancyContactNo = res.data[0].EmergencyContactNo;
                    this.form.data.emailId = res.data[0].Email;
                    this.form.data.alternateEmail = res.data[0].AlternateEmailId;
                    this.form.data.DOB = res.data[0].DOB;
                    this.form.data.bloodGroup = res.data[0]['Blood Group'];
                    this.form.data.isMarried = (res.data[0].IsMarried === 1)?true:false;
                    this.form.data.anniversaryDate = res.data[0].AnniversaryDate;
                    this.form.data.skypeId = res.data[0].SkypeId;
                    this.form.data.extension = res.data[0].Extension;
                    this.form.data.DIDNo = res.data[0].DIDNo;
                    this.form.data.aadhaarNo = res.data[0].AadhaarNo;
                    this.form.data.PANNo = res.data[0].PANNo;
                    this.form.data.voterIdCardNo = res.data[0].VoterIdCardNo;
                    this.form.data.passportNo = res.data[0].PassportNo;
                    this.form.data.passportExpiry = res.data[0].PassportExpiryDate;
                    this.form.data.visaDetails = res.data[0].VISADetails;
                    this.form.data.isServiceAgreement = (res.data[0].IsOnServiceAgreement === 1)?true:false;
                    this.form.data.serviceAgreementDate = res.data[0].serviceAgreementDate;
                    this.form.data.permanentAddress1 = res.data[0].Address1;
                    this.form.data.permanentAddress2 = res.data[0].Address2;
                    this.form.data.permanentAddress3 = res.data[0].Address3;
                    this.form.data.permanentCountryId = res.data[0].Country_ID;
                    this.form.data.permanentStateId = res.data[0].State_ID;
                    this.form.data.permanentCityId = res.data[0].City_ID;
                    this.form.data.permanentZipCode = res.data[0].ZipCode;
                    this.form.data.isSameAsPermanentAdd = (res.data[0].IsSameAsPermanentAddress === 1)?true:false;
                    this.form.data.communicationAddress1 = res.data[0].CommAddress1;
                    this.form.data.communicationAddress2 = res.data[0].CommAddress2;
                    this.form.data.communicationAddress3 = res.data[0].CommAddress3;
                    this.form.data.communicationCountryId = res.data[0].CommCountry_id;
                    this.form.data.communicationStateId = res.data[0].CommState_id;
                    this.form.data.communicationCityId = res.data[0].CommCity_id;
                    this.form.data.communicationZipCode = res.data[0].CommZipCode;
                });
                getStaffDetailsByUserID(data).then((res)=>{
                    this.form.data.gender = res.data[0].Gender;
                    this.form.data.nationality = res.data[0].Nationality;
                    this.form.data.companyId = res.data[0].Company_id;
                    this.form.data.devisionId = res.data[0].Division_id;
                    this.form.data.departmentId = res.data[0].Department_id;
                    this.form.data.designationId = res.data[0].Designation_id;
                    this.form.data.hrGroupId = res.data[0].HRGroup_id;
                    this.form.data.managerID = res.data[0].Manager_ID;
                    this.form.data.accountNo = res.data[0].BankAccNo;
                    this.form.data.accountName = res.data[0].AccountName;
                    this.form.data.PFNo = res.data[0].PFNo;
                    this.form.data.reportingLocationId = res.data[0].ReportingLocId;
                    this.form.data.phoneNo = '';
                    this.form.data.shiftId = res.data[0].Shift_id;
                    this.form.data.dateOfJoining = res.data[0].DateOfJoining;
                    this.form.data.dateOfConfirmation = res.data[0].probation_completion_date;
                    this.form.data.lastAppraisalDate = res.data[0].LastAppraisalDate;
                    this.form.data.appraisalDueOn = res.data[0].AppraisalDueDate;
                    this.form.data.separationNature = res.data[0].separatenature;
                    this.form.data.dateOfResignation = res.data[0].DateOfResignation;
                    this.form.data.dateOfRelieving = res.data[0].DateOfRelieving;
                    this.form.data.isManager = (res.data[0].IsManager === 1)?true:false;
                    this.form.data.isParttime = (res.data[0].IsParttime === 1)?true:false;
                    this.form.data.isOnSite = (res.data[0].IsOnSite === 1)?true:false;
                    this.form.data.isOnProbation = (res.data[0].IsOnProbation === 1)?true:false;
                    this.form.data.isActive = (res.data[0].IsActive === 1)?true:false;
                    this.form.data.isEPSApplicable = (res.data[0].EPS === 1)?true:false;
                });
            },
            updateForm(nextStep) {
                this.$refs.validator.validate().then((res) => {
                    if (res) {
                        //For Settings>My Profile>My Info (Add + View mode)
                        if(this.$route.name === 'profileInfo'){
                            //Save button should be only avilable for 'Profile Info step'
                            if(nextStep === 1){
                                this.showSaveButton = true;
                            } else{
                                this.showSaveButton = false;
                            }
                        } else{
                            //For Settings>Settings>Usermanagement>Add/Edit (Add/Edit mode)
                            //Save button should't be shown in Rewards or documents step
                            if(nextStep === 4 || nextStep === 5 || nextStep === 6){ //sagar add nextStep  condition nextStep===6
                                this.showSaveButton = false;
                            } else{
                                this.showSaveButton = true;
                            }
                        }
                        this.handleUpdate(nextStep);
                    }
                });
            },
            handleUpdate(nextStep){
                this.infoMessage.isVisible=false;
                if(this.activeStep.id === 1){
                    const formData = new FormData();
                    formData.append('id', this.form.data.userId?this.form.data.userId:0);
                    formData.append('userId', this.form.data.userId?this.form.data.userId:0);
                    formData.append('salutation', this.form.data.salutation?this.form.data.salutation:'');
                    formData.append('firstName', this.form.data.firstName?this.form.data.firstName:'');
                    formData.append('middleName', this.form.data.middleName?this.form.data.middleName:'');
                    formData.append('lastName', this.form.data.lastName?this.form.data.lastName:'');
                    formData.append('empCode', this.form.data.empCode?this.form.data.empCode:'');
                    formData.append('gradeId', this.form.data.gradeId?this.form.data.gradeId:'');
                    formData.append('userName', this.form.data.userName?this.form.data.userName:'');
                    formData.append('password', this.form.data.password?this.form.data.password:'');
                    formData.append('mobileNo', this.form.data.mobileNo?this.form.data.mobileNo:'');
                    formData.append('phoneNo1', this.form.data.phoneNo1?this.form.data.phoneNo1:'');
                    formData.append('phoneNo2', this.form.data.phoneNo2?this.form.data.phoneNo2:'');
                    formData.append('preferredPhone', this.form.data.preferredPhone?this.form.data.preferredPhone:'');
                    formData.append('emergancyContactNo', this.form.data.emergancyContactNo?this.form.data.emergancyContactNo:'');
                    formData.append('emailId', this.form.data.emailId?this.form.data.emailId:'');
                    formData.append('alternateEmail', this.form.data.alternateEmail?this.form.data.alternateEmail:'');
                    formData.append('DOB', this.form.data.DOB?this.form.data.DOB:'');
                    formData.append('bloodGroup', this.form.data.bloodGroup?this.form.data.bloodGroup:'');
                    formData.append('isMarried', this.form.data.isMarried?this.form.data.isMarried:0);
                    formData.append('anniversaryDate', this.form.data.anniversaryDate?this.form.data.anniversaryDate:'');
                    formData.append('skypeId', this.form.data.skypeId?this.form.data.skypeId:'');
                    formData.append('extension', this.form.data.extension?this.form.data.extension:'null');
                    formData.append('DIDNo', this.form.data.DIDNo?this.form.data.DIDNo:'');
                    formData.append('aadhaarNo', this.form.data.aadhaarNo?this.form.data.aadhaarNo:'');
                    formData.append('PANNo', this.form.data.PANNo?this.form.data.PANNo:'');
                    formData.append('voterIdCardNo', this.form.data.voterIdCardNo?this.form.data.voterIdCardNo:'');
                    formData.append('passportNo', this.form.data.passportNo?this.form.data.passportNo:'');
                    formData.append('passportExpiry', this.form.data.passportExpiry?this.form.data.passportExpiry:'');
                    formData.append('visaDetails', this.form.data.visaDetails?this.form.data.visaDetails:'');
                    formData.append('isServiceAgreement', this.form.data.isServiceAgreement?this.form.data.isServiceAgreement:0);
                    formData.append('serviceAgreementDate', this.form.data.serviceAgreementDate?this.form.data.serviceAgreementDate:'');                    
                    formData.append('permanentAddress1', this.form.data.permanentAddress1?this.form.data.permanentAddress1:'');
                    formData.append('permanentAddress2', this.form.data.permanentAddress2?this.form.data.permanentAddress2:'');
                    formData.append('permanentAddress3', this.form.data.permanentAddress3?this.form.data.permanentAddress3:'');
                    formData.append('permanentCountryId', this.form.data.permanentCountryId?this.form.data.permanentCountryId:0);
                    formData.append('permanentStateId', this.form.data.permanentStateId?this.form.data.permanentStateId:0);
                    formData.append('permanentCityId', this.form.data.permanentCityId?this.form.data.permanentCityId:0);
                    formData.append('permanentZipCode', this.form.data.permanentZipCode?this.form.data.permanentZipCode:0);
                    formData.append('isSameAsPermanentAdd', this.form.data.isSameAsPermanentAdd?this.form.data.isSameAsPermanentAdd:0);
                    formData.append('communicationAddress1', this.form.data.communicationAddress1?this.form.data.communicationAddress1:'');
                    formData.append('communicationAddress2', this.form.data.communicationAddress2?this.form.data.communicationAddress2:'');
                    formData.append('communicationAddress3', this.form.data.communicationAddress3?this.form.data.communicationAddress3:'');
                    formData.append('communicationCountryId', this.form.data.communicationCountryId?this.form.data.communicationCountryId:0);
                    formData.append('communicationStateId', this.form.data.communicationStateId?this.form.data.communicationStateId:0);
                    formData.append('communicationCityId', this.form.data.communicationCityId?this.form.data.communicationCityId:0);
                    formData.append('communicationZipCode', this.form.data.communicationZipCode?this.form.data.communicationZipCode:'');
                    formData.append('photoName', this.form.data.photoName?this.form.data.photoName:'');
                    
                    addUpdateStaffInfo(formData).then((res)=>{
                        if(res.data[0].status === 1){
                            this.form.data.userId = this.form.data.userId?this.form.data.userId:res.data[0].id;
                            this.form.data.displayPicture = res.data[0].PhotoPath?res.data[0].PhotoPath:'';
                            setTimeout(() => {
                                this.moveStep(nextStep);
                            },
                            50
                            )
                        } else{
                            this.infoMessage.isVisible=true;
                            this.infoMessage.message=res.data[0].message;
                        }
                    });
                } else if(this.activeStep.id === 2){
                    updateUserDetails(this.form.data).then((res)=>{
                        if(res.data[0].status === 1){
                            setTimeout(() => {
                                this.moveStep(nextStep);
                            },
                            50
                            )
                        } else{
                            this.infoMessage.isVisible=true;
                            this.infoMessage.message=res.data[0].message;
                        }
                        
                    });
                } else if(this.activeStep.id === 3){
                    let roles = '';
                    if (localStorage.getItem("rolesAssigned")) {
                        const earlierRoles = JSON.parse(localStorage.getItem('rolesAssigned'));
                        roles = earlierRoles.map(ele => ele).join(', ');
                        let arr=roles.split(",");
                        roles= [...new Set(arr)];
                        roles=roles.join(",");
                    }
                    const data = {
                        userId: this.form.data.userId?this.form.data.userId:0,
                        roleIds: roles
                    };
                    addUserRoleV1(data).then((res)=>{
                        if(res.data[0].status === 1){
                            setTimeout(() => {
                                this.moveStep(nextStep);
                            },
                            50
                            )
                            localStorage.removeItem('rolesAssigned');
                        } else{
                            this.infoMessage.isVisible=true;
                            this.infoMessage.message=res.data[0].message;
                        }
                    });
                } else{
                    this.moveStep(nextStep);
                }
            },
            moveStep(nextStep){
                if (nextStep) {
                    this.activateSteps(nextStep);
                    this.setActiveStep(nextStep);
                }else{
                    if(this.activeStep.id === 5){
                        this.setActiveStep(this.activeStep.id);
                    } else{
                        this.activateSteps(this.activeStep.id + 1);
                        this.setActiveStep(this.activeStep.id + 1);
                    }
                    
                }
            },
            beforeDestroy(){
                this.resetActivatedSteps();
            }
        },
    }
</script>

<style lang="scss" module>
    .formContent {
        h6 {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        p {
            text-align: justify;
            margin-bottom: 20px;
        }
        ol {
            padding-left: 40px;
            margin-bottom: 20px;
            li {
                margin-bottom: 10px;
            }
        }
    }
</style>