<template>
    <div :class="$style.actions">
        <div >
            <template>
                <input :id="row.ID" type="checkbox" v-model="selectedRole" :value="row.ID" @change="handleChange">
            </template>
        </div>
    </div>
</template>
<!-- sagar changes in roles function -->
<script>
    export default {
        name: "RolesActionsCell",
        props: {
            row: {
                type: Object,
                required: true,
            }
        },
        components: {
        },
        data() {
            return {
                selectedRole: false,
            }
        },
        computed:{
            userData() {
                return this.$store.state.user.user
            }
        },
        created(){
            if(this.row.Assigned == 1|| this.row.ID == 50){  //sagar id=50 means staff role always selected
                this.selectedRole = true;
                this.storeValue(this.row.ID);
            } else{
                this.selectedRole = false;
            }
        },
        methods: {
            handleChange(e) {
                const { value, checked } = e.target;
                if (checked) {
                    this.storeValue(value,1);
                }else{
                    this.storeValue(value,0); 
                }
            },
            storeValue(value,val){
                if (localStorage.getItem("rolesAssigned")) {
                    const earlierRoles = JSON.parse(localStorage.getItem('rolesAssigned'));
                    // const index = earlierRoles.findIndex(id => id === value);
                    const index = earlierRoles.findIndex(id => id === parseInt(value));
                    if (index == -1) { //the index is not present
                        // const newData = [...earlierRoles, value];
                        const newData = [...earlierRoles, parseInt(value)];
                        localStorage.setItem('rolesAssigned', JSON.stringify(newData));
                    }
                        // sagar changes remove uncheck value from list 30-08-2023 
                        else if(val==0){
                    let assign=JSON.parse(localStorage.getItem('rolesAssigned'));
                    let arr = assign.filter((item)=> {
                    return item !== parseInt(value)
                    })
                        localStorage.setItem('rolesAssigned', JSON.stringify(arr));
                    }
                } else {
                    // localStorage.setItem('rolesAssigned', JSON.stringify([value]));
                    localStorage.setItem('rolesAssigned', JSON.stringify([parseInt(value)]));
                    const earlierRoles = JSON.parse(localStorage.getItem('rolesAssigned'));
                }
            }
        }
    }
</script>

<style lang="scss" module>

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        a,
        button {
            margin: 0 5px
        }
        div {
            margin: 0 5px;
            color: #55a7a0;
            display: inline-block;
            &:hover {
                color: #55a7a0;
            }
            &.error {
                color: #eac222;
                font-size: 19px;
                &:hover {
                    font-size: 19px;
                }
            }
        }
    }
</style>

